<template>
  <div class="card card-custom gutter-b" v-if="conditionsForLoadingSheet">
    <div class="card-header card-header-tabs-line">
      <div class="card-title">
        <ActionButton
          class="mr-3"
          v-b-toggle.sheetdaten-collapse
          title="Ausklappen"
          :iconClass="isCollapseOpen ? 'ki ki-arrow-up' : 'ki ki-arrow-down'"
          @click="toggleCollapse"
          v-if="!isTerminIn24"
        />
        <h3 class="card-label" v-if="isTerminIn24">Sheetdaten</h3>
        <h3 class="card-label" v-else>{{ correctTitle }}</h3>

        <div class="card-toolbar"></div>
      </div>
      <ul class="nav nav-tabs nav-bold nav-tabs-line">
        <li class="nav-item" v-if="isTerminIn24">
          <router-link
            :to="{
              path: '',
              query: { ...this.$route.query, sheetdatatab: 'flugzeitensheet' },
            }"
            v-slot="{ href }"
          >
            <a class="nav-link" :class="{ active: activeTabFromRouter === 'flugzeitensheet' }" :href="href">
              <span class="nav-icon"><i class="fas fa-plane"></i></span>

              <span class="nav-text">Flugzeitensheet</span>
            </a>
          </router-link>
        </li>
        <li class="nav-item" v-if="isTerminNach24">
          <router-link
            :to="{
              path: '',
              query: { ...this.$route.query, sheetdatatab: 'flugdaten' },
            }"
            v-slot="{ href }"
          >
            <a class="nav-link" :class="{ active: activeTabFromRouter === 'flugdaten' }" :href="href">
              <span class="nav-icon"><i class="fas fa-plane"></i></span>
              <span class="nav-text">Flugdaten</span>
            </a>
          </router-link>
        </li>
        <li class="nav-item" v-if="showAbwicklungssheet">
          <router-link
            :to="{
              path: '',
              query: { ...this.$route.query, sheetdatatab: 'abwicklungssheet' },
            }"
            v-slot="{ href }"
          >
            <a class="nav-link" :class="{ active: activeTabFromRouter === 'abwicklungssheet' }" :href="href">
              <span class="nav-icon"><i class="fas fa-list"></i></span>
              <span class="nav-text">Abwicklungssheet</span>
            </a>
          </router-link>
        </li>
      </ul>
    </div>
    <b-collapse visible id="sheetdaten-collapse">
      <div class="card-body">
        <div
          v-show="activeTabFromRouter === 'abwicklungssheet'"
          :class="{ show: activeTabFromRouter === 'abwicklungssheet' }"
          class="tab-pane active"
          v-if="showAbwicklungssheet"
        >
          <div
            v-if="sheetdata?.abwicklungssheet"
            class="d-flex align-items-baseline py-2 overflow-auto scroll scroll-pull"
            data-scroll="true"
            data-wheel-propagation="true"
          >
            <div
              class="mb-2 flex-shrink-0"
              v-for="(value, key) in sheetdata.abwicklungssheet"
              :key="key + value"
            >
              <div class="mr-6 d-flex flex-column align-items-baseline" v-if="value !== '' && value !== '-'">
                <div class="font-weight-bold mb-4">
                  {{ key }}
                </div>
                <span
                  class="rounded p-3 bg-light-primary text-primary"
                  style="max-width: 400px; white-space: pre-line"
                >
                  {{ value }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-show="activeTabFromRouter === 'flugdaten'"
          :class="{ show: activeTabFromRouter === 'flugdaten' }"
          class="tab-pane active"
        >
          <ReiseterminCardFlugdataReleasepruefung
            :reiseterminObj="reiseterminObj"
          ></ReiseterminCardFlugdataReleasepruefung>
        </div>
        <div
          v-show="activeTabFromRouter === 'flugzeitensheet'"
          :class="{ show: activeTabFromRouter === 'flugzeitensheet' }"
          class="tab-pane active"
        >
          <div class="overflow-auto scroll scroll-pull" data-scroll="true" data-wheel-propagation="true">
            <b-table
              v-if="relevantData"
              responsive="sm"
              striped
              hover
              :items="relevantData?.items"
              :fields="relevantData?.fields"
            >
              <template #thead-top="data">
                <b-th variant="secondary" colspan="4"></b-th>
                <b-th variant="primary" :colspan="sheetdata?.region === 'eu' ? 7 : 8">Hinflug</b-th>
                <b-th variant="danger" :colspan="sheetdata?.region === 'eu' ? 7 : 8">Rückflug</b-th>
                <b-th variant="secondary" colspan="2"></b-th>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>
<script>
import axios from 'axios';
import { fernRegions } from '@/core/common/helpers/utils.js';
import ActionButton from '@/components/common/action-button.vue';
import ReiseterminCardFlugdataReleasepruefung from '@/components/produkte/reisetermine/reisetermin-card-flugdata-releasepruefung.vue';

export default {
  props: {
    reiseterminObj: { type: Object },
  },
  components: {
    ActionButton,
    ReiseterminCardFlugdataReleasepruefung,
  },
  data() {
    return {
      sheetdata: null,
      currentFullPath: this.$route.fullPath,
      relevantData: null,
      isCollapseOpen: true,
    };
  },
  watch: {
    reiseterminObj(newReiseterminObj) {
      if (newReiseterminObj && this.conditionsForLoadingSheet) {
        this.loadData();
      }
    },
    $route(to) {
      this.currentFullPath = to.fullPath;
    },
  },
  computed: {
    isTerminIn24() {
      if (!this.reiseterminObj) {
        return false;
      }
      return new Date(this.reiseterminObj.startdatum).getFullYear() === 2024;
    },
    isTerminNach24() {
      if (!this.reiseterminObj) {
        return false;
      }
      return new Date(this.reiseterminObj.startdatum).getFullYear() === 2025;
    },
    conditionsForLoadingSheet() {
      if (this.reiseterminObj) {
        const reiseterminFullYear = new Date(this.reiseterminObj.startdatum).getFullYear();
        return (
          reiseterminFullYear < 2025 ||
          (reiseterminFullYear === 2025 && fernRegions.includes(this.reiseterminObj.reise.region))
        );
      }
      return false;
    },
    showAbwicklungssheet() {
      if (this.currentFullPath.includes('releasepruefung')) {
        return true;
      }
      return false;
    },
    activeTabFromRouter() {
      return (
        this.$route?.query?.sheetdatatab ||
        (this.$route?.fullPath.includes('releasepruefung') ? 'abwicklungssheet' : 'flugzeitensheet')
      );
    },
    correctTitle() {
      return this.activeTabFromRouter === 'flugdaten' ? 'Flugdaten' : 'Abwicklungssheet';
    },
  },
  methods: {
    loadData() {
      axios
        .get(
          'https://api.skr.de/get-sheet-data-releaseprocess?code=raLGznxXngQKaCUUcRX3BHH05FTNSRkbEXdYLNZRRzOUAzFuAZsFPQ==&reiseterminkuerzel=' +
            this.reiseterminObj.reiseterminkuerzel
        )
        .then(response => {
          const filterOutKeys = ['Region', 'Status', 'Reisekürzel', 'Transferzeiten TUKA'];
          const filteredAbwicklungssheet = Object.keys(response.data?.abwicklungsdata)
            .filter(key => !filterOutKeys.includes(key))
            .reduce((obj, key) => {
              obj[key] = response.data?.abwicklungsdata[key];
              return obj;
            }, {});
          this.sheetdata = {
            abwicklungssheet: filteredAbwicklungssheet,
            flugzeitensheet: response.data?.flugzeitendata,
            region: response.data?.region,
          };
          this.relevantData = this.getRelevantDataFromFlugzeitensheet();
        })
        .catch(error => {
          console.error(error);
        });
    },
    getRelevantDataFromFlugzeitensheet() {
      console.log(this.sheetdata.region);
      if (this.sheetdata && this.sheetdata.flugzeitensheet) {
        if (this.sheetdata.region === 'eu') {
          return {
            items: this.sheetdata.flugzeitensheet.map(item => ({
              reiseterminkuerzel: item['Reiseterminkürzel lt. SKR Manager'],
              transferszenario_hf: item['Transferszenario HF'],
              transferszenario_rf: item['Transferszenario RF'],
              einkaufsart: item['Einkaufsart'],
              pnr: item['Wenn Gruppe: PNR'],
              hinflug_abflugdatum: item['hinflug_Abflug-Datum'],
              hinflug_ankunftsdatum:
                item[Object.keys(item).find(key => key.includes('hinflug_Ankunftsdatum'))],
              hinflug_abflughafen: item[Object.keys(item).find(key => key.includes('hinflug_Abflug- hafen'))],
              hinflug_zielflughafen: item['hinflug_Zielflug- hafen'],
              hinflug_ankunft_bis: item['hinflug_Ankunft bis'],
              hinflug_flug_nr: item['hinflug_Flug-Nr. (wenn Richtflug)'],
              hinflug_transfer_inkl_ab: item['hinflug_Transfer inkl. ab'],
              hinflug_transfer_inkl_bis: item['hinflug_Transfer inkl. bis'],
              rueckflug_abflugdatum: item['rueckflug_Abflug-Datum'],
              rueckflug_abflughafen: item['rueckflug_Abflug- hafen'],
              rueckflug_zielflughafen: item['rueckflug_Zielflug- hafen (bei GRP)'],
              rueckflug_abflug_ab: item['rueckflug_Abflugab'],
              rueckflug_flug_nr: item['rueckflug_Flug-Nr. (wenn Richtflug)'],
              rueckflug_transfer_inkl_ab: item['rueckflug_Transfer inkl. ab'],
              rueckflug_transfer_inkl_bis: item['rueckflug_Transfer inkl. bis'],
              transferdauer_hin: item[Object.keys(item).find(key => key.includes('Transferdauer HIN'))],
              transferdauer_rueck: item[Object.keys(item).find(key => key.includes('Transferdauer RÜCK'))],
            })),
            fields: [
              { key: 'transferszenario_hf', label: 'Transferszenario HF' },
              { key: 'transferszenario_rf', label: 'Transferszenario RF' },
              { key: 'einkaufsart', label: 'Einkaufsart' },
              { key: 'pnr', label: 'PNR' },
              { key: 'hinflug_abflugdatum', label: 'Abflugdatum' },
              { key: 'hinflug_abflughafen', label: 'Abflughafen' },
              { key: 'hinflug_zielflughafen', label: 'Zielflughafen' },
              { key: 'hinflug_ankunft_bis', label: 'Ankunft bis' },
              { key: 'hinflug_flug_nr', label: 'Flug Nr.' },
              { key: 'hinflug_transfer_inkl_ab', label: 'Transfer inkl. ab' },
              { key: 'hinflug_transfer_inkl_bis', label: 'Transfer inkl. bis' },
              { key: 'rueckflug_abflugdatum', label: 'Abflugdatum' },
              { key: 'rueckflug_abflughafen', label: 'Abflughafen' },
              { key: 'rueckflug_zielflughafen', label: 'Zielflughafen' },
              { key: 'rueckflug_abflug_ab', label: 'Abflug ab' },
              { key: 'rueckflug_flug_nr', label: 'Flug Nr.' },
              { key: 'rueckflug_transfer_inkl_ab', label: 'Transfer inkl. ab' },
              { key: 'rueckflug_transfer_inkl_bis', label: 'Transfer inkl. bis' },
              { key: 'transferdauer_hin', label: 'Transferdauer Hin' },
              { key: 'transferdauer_rueck', label: 'Transferdauer Rück' },
            ],
          };
        } else {
          return {
            items: this.sheetdata.flugzeitensheet.map(item => ({
              transferszenario_hf: item['Transferszenario HF'],
              transferszenario_rf: item['Transferszenario RF'],
              reiseterminkuerzel: item['Reiseterminkürzel lt. SKR Manager'],
              einkaufsart: item['Einkaufsart'],
              pnr: item['Wenn Gruppe: PNR'],
              hinflug_abflugdatum: item[Object.keys(item).find(key => key.includes('hinflug_Abflug-Datum'))],
              hinflug_abflughafen: item[Object.keys(item).find(key => key.includes('hinflug_Abflug- hafen'))],
              hinflug_zielflughafen: item['hinflug_Zielflug- hafen'],
              hinflug_ankunft_ab: item[Object.keys(item).find(key => key.includes('hinflug_Ankunft ab'))],
              hinflug_ankunft_bis: item[Object.keys(item).find(key => key.includes('hinflug_Ankunft bis'))],
              hinflug_flug_nr: item['hinflug_Flug-Nr. (wenn Richtflug)'],
              hinflug_transfer_inkl_ab: item['hinflug_Transfer inkl. ab'],
              hinflug_transfer_inkl_bis: item['hinflug_Transfer inkl. bis'],
              rueckflug_ankunftsdatum: item['rueckflug_Ankunfts- Datum'],
              rueckflug_abflughafen: item['rueckflug_Abflug- hafen'],
              rueckflug_zielflughafen: item['rueckflug_Zielflug- hafen (bei GRP)'],
              rueckflug_abflug_ab: item[Object.keys(item).find(key => key.includes('rueckflug_Abflug ab'))],
              rueckflug_abflug_bis: item[Object.keys(item).find(key => key.includes('rueckflug_Abflug bis'))],
              rueckflug_flug_nr: item['rueckflug_Flug-Nr. (wenn Richtflug)'],
              rueckflug_transfer_inkl_ab: item['rueckflug_Transfer inkl. ab'],
              rueckflug_transfer_inkl_bis: item['rueckflug_Transfer inkl. bis'],
              transferdauer_hin: item[Object.keys(item).find(key => key.includes('Transferdauer HIN'))],
              transferdauer_rueck: item[Object.keys(item).find(key => key.includes('Transferdauer RÜCK'))],
            })),
            fields: [
              { key: 'transferszenario_hf', label: 'Transferszenario HF' },
              { key: 'transferszenario_rf', label: 'Transferszenario RF' },
              { key: 'einkaufsart', label: 'Einkaufsart' },
              { key: 'pnr', label: 'PNR' },
              { key: 'hinflug_abflugdatum', label: 'Abflugdatum' },
              { key: 'hinflug_ankunft_ab', label: 'Ankunft ab' },
              { key: 'hinflug_ankunft_bis', label: 'Ankunft bis' },
              { key: 'hinflug_abflughafen', label: 'Abflughafen' },
              { key: 'hinflug_zielflughafen', label: 'Zielflughafen' },
              { key: 'hinflug_flug_nr', label: 'Flug Nr.' },
              { key: 'hinflug_transfer_inkl_ab', label: 'Transfer inkl. ab' },
              { key: 'hinflug_transfer_inkl_bis', label: 'Transfer inkl. bis' },
              { key: 'rueckflug_abflug_ab', label: 'Abflug ab' },
              { key: 'rueckflug_abflug_bis', label: 'Abflug bis' },
              { key: 'rueckflug_ankunftsdatum', label: 'Ankunftsdatum' },
              { key: 'rueckflug_abflughafen', label: 'Abflughafen' },
              { key: 'rueckflug_zielflughafen', label: 'Zielflughafen' },
              { key: 'rueckflug_flug_nr', label: 'Flug Nr.' },
              { key: 'rueckflug_transfer_inkl_ab', label: 'Transfer inkl. ab' },
              { key: 'rueckflug_transfer_inkl_bis', label: 'Transfer inkl. bis' },
              { key: 'transferdauer_hin', label: 'Transferdauer Hin' },
              { key: 'transferdauer_rueck', label: 'Transferdauer Rück' },
            ],
          };
        }
      }
      return null;
    },
    toggleCollapse() {
      this.isCollapseOpen = !this.isCollapseOpen;
    },
  },
  mounted() {
    if (this.reiseterminObj && this.conditionsForLoadingSheet) {
      this.loadData();
    }
  },
};
</script>
